<template>
  <layout-stage backgroundColor="#fcebc3" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="data-sheet">
          <div class="data-sheet_box">
            <div class="data-sheet_infos">
              <p><span>FICHA TÉCNICA</span></p>

              <p><span>LOCALIZAÇÃO:</span> RUA HELIANTO, 100 - NOVA SUÍÇA</p>

              <p><span>CONSTRUÇÃO:</span> DIRECIONAL ENGENHARIA</p>

              <p><span>ARQUITETURA:</span> TORRES MIRANDA ARQUITETURA</p>

              <p>
                <span>PAISAGISMO:</span> FORMA GARDEN ARQUITETURA PAISAGÍSTICA
              </p>

              <p><span>ARQUITETURA DE INTERIORES:</span> ÓBVIO ARQUITETURA</p>

              <p><span>ÁREA DO TERRENO:</span> 3.356,50 M²</p>

              <p><span>NÚMERO DE TORRES:</span> 1 TORRE</p>

              <p>
                <span>NÚMERO DE UNIDADES:</span> 119 UNIDADES RESIDENCIAIS + 5
                LOJAS
              </p>

              <p><span>NÚMERO DE PAVIMENTOS:</span> 25 PAVIMENTOS</p>

              <p><span>NÚMERO DE ELEVADORES:</span> 2 UNIDADES</p>

              <p><span>TIPOLOGIA:</span></p>

              <ul>
                <li>
                  • 72 UNIDADES APARTAMENTOS 2 QUARTOS COM SUÍTE DE 58,90M²
                </li>

                <li>
                  • 35 UNIDADES APARTAMENTOS DE 2 QUARTOS COM SUÍTE DE 58,01M2
                </li>

                <li>• 06 UNIDADES ÁREA PRIVATIVA</li>

                <li>• 06 UNIDADES COBERTURA DUPLEX</li>
              </ul>

              <p>
                <span>VAGAS:</span> APARTAMENTOS COM 2 VAGAS VINCULADAS - EXCETO
                APTOS 604, 605, 702, 802, 902 E 1.002
              </p>

              <p><span>ÁREA COMUNS:</span></p>

              <ul>
                <li>• ACADEMIA</li>

                <li>• CHURRASQUEIRA</li>

                <li>• SALÃO DE FESTAS</li>

                <li>• LOUNGE FIRE</li>

                <li>• LOUNGE EXTERNO</li>

                <li>• PET PLACE</li>

                <li>• QUADRA ESPORTIVA</li>

                <li>• PISCINAS ADULTO E INFANTIL</li>

                <li>• SAUNA COM DUCHA</li>
              </ul>

              <p><span>ACABAMENTOS NOS APARTAMENTOS:</span></p>

              <ul>
                <li>• PISO LAMINADO NOS QUARTOS</li>

                <li>
                  • PISO EM PORCELANATO NOS BANHEIROS, SALA, COZINHA E VARANDA
                </li>

                <li>• JANELA COM VENEZIANA EM ALUMÍNIO BRANCO NOS QUARTOS</li>

                <li>• INFRAESTRUTURA PARA AR CONDICIONADO NOS QUARTOS</li>

                <li>• TOMADA USB NAS SUÍTES</li>
              </ul>

              <p>
                <span>ACABAMENTO FACHADA:</span> PINTURA TEXTURIZADA PARCIALMENTE REVESTIDA EM PORCELANATO
              </p>
            </div>
          </div>
        </div>
        <img
          class="image-datasheet"
          src="~@/assets/images/bg-datasheet.png"
          alt=""
        />
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";

export default {
  name: "ProjectDatasheet",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .image-datasheet {
    object-fit: cover;
    max-width: 800px;
    height: 880px;
    border-radius: 30px 0 0 0;
  }

  .data-sheet {
    margin-top: 50px;
    .data-sheet_box {
      padding: 57px;
      background-color: var(--tumbleweed);
      height: 767px;
      width: 538px;
      border-radius: 30px;
      .data-sheet_infos {
        overflow-y: auto;
        height: 650px;

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--blackRose);
          margin-bottom: 16px;

          span {
            font-weight: var(--weight-bold);
          }
        }

        ul {
          li {
            font-size: 20px;
            line-height: 26px;
            color: var(--blackRose);
            margin-bottom: 16px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--oasis);
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-datasheet {
      max-width: 490px;
      height: 700px;
    }

    .data-sheet {
      .data-sheet_box {
        padding: 38px;
        height: 500px;
        width: 380px;

        .data-sheet_infos {
          height: 420px;

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    .image-datasheet {
      margin-left: 30px;
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .image-datasheet {
      max-width: 290px;
      height: 350px;
      margin-left: 0px;
    }

    .data-sheet {
      margin-top: 20px;

      .data-sheet_box {
        padding: 18px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .data-sheet_infos {
          height: 220px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 478px;
    max-height: 210px;

    .image-datasheet {
      max-width: 250px;
      height: 290px;
    }

    .data-sheet {
      .data-sheet_box {
        height: 180px;
        width: 210px;

        .data-sheet_infos {
          height: 140px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }

          ul li {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;

    .image-datasheet {
      max-width: 200px;
      height: 280px;
    }

    .data-sheet {
      .data-sheet_box {
        height: 180px;

        .data-sheet_infos {
          height: 140px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-data-sheet {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--tumbleweed);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
