<template>
  <layout-stage backgroundColor="#5c2e3d" themeName="about">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'About' }" exact>
              <icon-store />
              Conveniências
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AboutPhotos' }" exact>
              <icon-square />
              Fotos interativas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'About360' }" exact>
              <icon-compass />
              Vista 360º
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <iframe
          width="100%"
          height="500px"
          class="iframe-360"
          allowfullscreen="false"
          src="https://momento360.com/e/u/d5ab3f30897f4704a2ebcb41cff033a7?utm_campaign=embed&utm_source=other&size=medium"
        >
        </iframe>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconCompass from "@/components/icons/IconCompass";
import IconSquare from "@/components/icons/IconSquare";
import IconStore from "@/components/icons/IconStore";

export default {
  name: "About360",

  components: {
    LayoutStage,
    IconBack,
    IconCompass,
    IconSquare,
    IconStore,
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 50px;
  max-width: 900px;
  width: 100%;
  position: relative;

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    margin-top: 0px;
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 500px;
    position: fixed;
    right: 0;
    bottom: 10px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 550px;
  }

  @media (max-width: 568px) {
  }
}

.iframe-360 {
  border-radius: 30px 0 0 0;
  width: 1340px;
  position: absolute;
  top: 0px;
  right: -40px;
  height: 935px;
  display: block;
  margin: 0 0 0 0;
  border: 0 none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 709px;
    width: 940px;
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    width: 830px;
    height: 685px;
    top: 55px;
    right: -75px;
  }

  @media (max-width: 1024px) {
    width: 760px;
    height: 628px;
    top: 45px;
    right: -75px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 560px;
    height: 330px;
    top: 15px;
    right: 0px;
    position: relative;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    width: 615px;
    height: 240px;
    top: 40px;
    right: -75px;
  }

  @media (max-width: 568px) {
    width: 440px;
    height: 235px;
    top: 15px;
    right: -75px;
  }
}

.back-button {
  color: var(--oasis);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--oasis);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--oasis);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }

  @media (max-width: 1200px) {
    li:nth-child(2) {
      display: none;
    }
  }
}
</style>
