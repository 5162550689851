<template>
  <nav class="main-menu animate__animated animate__bounceInLeft">
    <router-link :to="{ name: 'About' }" exact>
      <img
        class="icon-menu"
        alt="Icon About"
        src="~@/assets/images/icon-about.svg"
      />
      Sobre o GoUP
      <img
        class="
          icon-arrow
          animate__animated
          animate__headShake
          animate__delay-2s
          animate__infinite
        "
        alt=""
        src="~@/assets/images/icon-arrow-right.svg"
      />
    </router-link>
    <router-link :to="{ name: 'Project' }" exact>
      <img
        class="icon-menu"
        alt="Icon Project"
        src="~@/assets/images/icon-project.svg"
      />
      Projeto
      <img
        class="
          icon-arrow
          animate__animated
          animate__headShake
          animate__delay-2s
          animate__infinite
        "
        alt=""
        src="~@/assets/images/icon-arrow-right.svg"
      />
    </router-link>
    <router-link :to="{ name: 'CommonAreas' }" exact>
      <img
        class="icon-menu"
        alt="Icon Common Area"
        src="~@/assets/images/icon-commom-area.svg"
      />
      Áreas comuns
      <img
        class="
          icon-arrow
          animate__animated
          animate__headShake
          animate__delay-2s
          animate__infinite
        "
        alt=""
        src="~@/assets/images/icon-arrow-right.svg"
      />
    </router-link>
    <router-link :to="{ name: 'Units' }" exact>
      <img
        class="icon-menu"
        alt="Icon Unit"
        src="~@/assets/images/icon-unit.svg"
      />
      Unidades
      <img
        class="
          icon-arrow
          animate__animated
          animate__headShake
          animate__delay-2s
          animate__infinite
        "
        alt=""
        src="~@/assets/images/icon-arrow-right.svg"
      />
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "MainMenu",
};
</script>

<style lang="scss" scoped>
.main-menu {
  display: flex;
  flex-direction: column;
  margin: 230px 0 0 20px;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    color: var(--oasis);
    font-size: 50px;
    line-height: 76px;
    font-weight: var(--weight-regular);

    .icon-arrow {
      margin-left: 9px;
      width: 32px;
      height: 32px;
    }

    .icon-menu {
      width: 56px;
      margin-right: 32px;
      height: auto;
      display: inline-block;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  //Responsive mode
  @media (max-width: 1680px) {
    margin: 130px 0 0 20px;
    a {
      font-size: 40px;
      line-height: 66px;

      .icon-menu {
        width: 48px;
        margin-right: 32px;
        height: auto;
        display: inline-block;
      }
    }
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin: 170px 0 0 0px;
    a {
      font-size: 35px;
      line-height: 61px;

      .icon-menu {
        width: 43px;
        margin-right: 24px;
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin: 90px 0 0 0px;
    a {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;

      .icon-arrow {
        width: 20px;
        height: 20px;
      }

      .icon-menu {
        width: 26px;
        margin-right: 16px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    margin: 30px 0 0 0px;
  }
}
</style>
