<template>
  <layout-stage backgroundColor="#9eb9df" themeName="units">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Units' }" exact>
              <icon-apartment />
              Apartamentos
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Units360' }" exact>
              <icon-compass />
              Vista 360º
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="myUnitSwiper" :options="swiperOption">
          <swiper-slide
            class="slide-unit"
            v-for="(unit, index) in MockUnits"
            :key="index"
          >
            <div class="slide-unit_content">
              <div class="header-units">
                <h2>{{ unit.name }}</h2>
                <h3>
                  {{ unit.meters }}
                  <span v-if="unit.details">{{ unit.details }}</span>
                </h3>
                <button
                  @click="setUnitCompare(unit, index)"
                  class="button-compare"
                >
                  Comparar
                </button>
              </div>

              <img
                class="icon-eye-mobile"
                src="~@/assets/images/icon-eye.svg"
                v-if="unit.PhotosUnit"
                @click="openModalPhotosMobile(unit.PhotosUnit)"
              />

              <div class="box-image-unit" :class="'box-image-unit_' + index">
                <div class="show-photos">
                  <span v-for="(photo, index) in unit.PhotosUnit" :key="index">
                    <img
                      :class="['icon-eye', 'icon-eye_' + photo.name]"
                      src="~@/assets/images/icon-eye.svg"
                      @click="openPhoto(photo.url)"
                      v-show="photo.name !== 'externa' && !openDoubleImage"
                    />
                    <img
                      class="icon-eye"
                      :class="'icon-eye_' + photo.name"
                      src="~@/assets/images/icon-eye.svg"
                      @click="openPhoto(photo.url)"
                      v-show="openDoubleImage && photo.name === 'externa'"
                    />
                  </span>
                </div>

                <img
                  :class="[
                    'image-compare-unit_' + index,
                    unit.imageUnitDouble &&
                    openDoubleImage &&
                    index === setIndex
                      ? 'hide-image'
                      : '',
                  ]"
                  :src="unit.imageUnit"
                  alt=""
                />
                <img
                  v-if="openDoubleImage && index === setIndex"
                  :class="'image-compare-doubleunit_' + index"
                  :src="unit.imageUnitDouble"
                  alt=""
                />
              </div>
              <a
                @click="openImageUnitDouble(index)"
                v-if="unit.imageUnitDouble && !openDoubleImage"
                class="button-doubleUnit"
                >Ver 2º pavimento</a
              >
              <a
                @click="openImageUnitDouble(index)"
                v-if="unit.imageUnitDouble && openDoubleImage"
                class="button-doubleUnit"
                >Ver 1º pavimento</a
              >
              <img
                :class="'image-mapper-unit_' + index"
                :src="unit.imageMapper"
                alt=""
              />
            </div>
          </swiper-slide>
        </swiper>

        <div class="box-controllers">
          <div class="pagination-static">
            <div
              @click="resetDoubleImage()"
              class="swiper-button-prev"
              slot="button-prev"
            ></div>
            <div
              @click="resetDoubleImage()"
              class="swiper-button-next"
              slot="button-next"
            ></div>
          </div>
        </div>
      </section>

      <modal-photo
        :photo="openPhotoSelected"
        v-if="showModalPhotos"
        @close="showModalPhotos = false"
      />

      <modal-multiple-photos
        :photos="openPhotosMobileSelected"
        v-if="showModalPhotosMobile"
        @close="showModalPhotosMobile = false"
      />

      <modal-compare
        :activeUnit="unitSelectedToCompare"
        :carouselUnits="MockUnits"
        :indexActiveUnit="indexActive"
        v-if="showModal"
        @close="showModal = false"
      />
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import ModalCompare from "@/components/ModalCompare";
import ModalPhoto from "@/components/ModalPhoto";
import ModalMultiplePhotos from "@/components/ModalMultiplePhotos";
import IconBack from "@/components/icons/IconBack";
import IconApartment from "@/components/icons/IconApartment";
import IconCompass from "@/components/icons/IconCompass";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Units",

  components: {
    LayoutStage,
    ModalCompare,
    ModalPhoto,
    IconBack,
    IconApartment,
    IconCompass,
    Swiper,
    SwiperSlide,
    ModalMultiplePhotos,
  },

  data() {
    return {
      MockUnits: [
        {
          name: "Tipo PCD",
          type: "common",
          meters: "58,90m²",
          imageUnit: require("@/assets/images/image-unit-pcd.png"),
          imageMapper: require("@/assets/images/mapper-unit-pcd.png"),
        },
        {
          name: "Tipo ponta terraço",
          type: "common",
          meters: "58,90m²",
          imageUnit: require("@/assets/images/image-unit-pontaterraco.png"),
          imageMapper: require("@/assets/images/mapper-unit-pontaterraco.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/aptoponta_sala_goup.jpg"),
              name: "sala",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_quartosolteiro_goup.jpg"),
              name: "quartosolteiro",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_quartocasal_goup.jpg"),
              name: "quartocasal",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_cozinha_goup.jpg"),
              name: "cozinha",
            },
          ],
        },
        {
          name: "Tipo ponta padrão",
          type: "common",
          meters: "58,90m²",
          imageUnit: require("@/assets/images/image-unit-pontapadrao.png"),
          imageMapper: require("@/assets/images/mapper-unit-pontapadrao.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/aptoponta_sala_goup.jpg"),
              name: "sala",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_quartosolteiro_goup.jpg"),
              name: "quartosolteiro",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_quartocasal_goup.jpg"),
              name: "quartocasal",
            },
            {
              url: require("@/assets/images/areas-internas/aptoponta_cozinha_goup.jpg"),
              name: "cozinha",
            },
          ],
        },
        {
          name: "Tipo meio terraço",
          type: "common",
          meters: "58,01m²",
          imageUnit: require("@/assets/images/image-unit-meioterraco.png"),
          imageMapper: require("@/assets/images/mapper-unit-meioterraco.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/aptomeio_sala_goup.jpg"),
              name: "sala",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_quartosolteiro_goup.jpg"),
              name: "quartosolteiro",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_quartocasal_goup.jpg"),
              name: "quartocasal",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_cozinha_goup.jpg"),
              name: "cozinha",
            },
          ],
        },
        {
          name: "Tipo meio padrão",
          type: "common",
          meters: "58,01m²",
          imageUnit: require("@/assets/images/image-unit-meiopadrao.png"),
          imageMapper: require("@/assets/images/mapper-unit-meiopadrao.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/aptomeio_sala_goup.jpg"),
              name: "sala",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_quartosolteiro_goup.jpg"),
              name: "quartosolteiro",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_quartocasal_goup.jpg"),
              name: "quartocasal",
            },
            {
              url: require("@/assets/images/areas-internas/aptomeio_cozinha_goup.jpg"),
              name: "cozinha",
            },
          ],
        },
        {
          name: "Cobertura meio",
          type: "double",
          meters: "113,81m²",
          details: "c/suíte",
          imageUnit: require("@/assets/images/image-unit-coberturameio.png"),
          imageUnitDouble: require("@/assets/images/image-unit-coberturameio2.png"),
          imageMapper: require("@/assets/images/mapper-unit-coberturameio.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/cobertura_externa_goup.jpg"),
              name: "externa",
            },
            {
              url: require("@/assets/images/areas-internas/cobertura_1pav_goup.jpg"),
              name: "1pav",
            },
          ],
        },
        {
          name: "Cobertura ponta",
          type: "double",
          meters: "112,04m²",
          details: "c/suíte",
          imageUnit: require("@/assets/images/image-unit-coberturaponta.png"),
          imageUnitDouble: require("@/assets/images/image-unit-coberturaponta2.png"),
          imageMapper: require("@/assets/images/mapper-unit-coberturaponta.png"),
          PhotosUnit: [
            {
              url: require("@/assets/images/areas-internas/cobertura_externa_goup.jpg"),
              name: "externa",
            },
            {
              url: require("@/assets/images/areas-internas/cobertura_1pav_goup.jpg"),
              name: "1pav",
            },
          ],
        },
      ],
      setIndex: null,
      openDoubleImage: false,
      openPhotoSelected: "",
      showModal: false,
      showModalPhotos: false,
      openPhotosMobileSelected: [],
      showModalPhotosMobile: false,
      unitSelectedToCompare: {},
      activeUnit: null,
      indexActive: null,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  mounted() {
    this.activeUnit = this.$refs.myUnitSwiper.$swiper.activeIndex;
  },

  methods: {
    resetDoubleImage() {
      this.openDoubleImage = false;
    },
    openImageUnitDouble(i) {
      this.openDoubleImage = !this.openDoubleImage;
      this.setIndex = i;
    },
    setUnitCompare(unit, index) {
      this.indexActive = index;
      this.unitSelectedToCompare = unit;
      this.showModal = true;
    },
    openPhoto(photoUrl) {
      this.openPhotoSelected = photoUrl;
      this.showModalPhotos = true;
    },
    openModalPhotosMobile(photos) {
      this.openPhotosMobileSelected = photos.map(function (item) {
        return item.url;
      });
      this.showModalPhotosMobile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-controllers {
  position: absolute;
  height: 146px;
  width: 241px;
  bottom: 123px;
  right: 0;
  z-index: 99;
  background: var(--frenchPass);
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-static {
    display: flex;
    margin-top: 15px;

    &::before {
      content: "";
      width: 2px;
      height: 120px;
      position: absolute;
      top: 15px;
      left: 121px;
      background: rgba(250, 250, 250, 0.2);
    }

    .swiper-button-next {
      right: 0;
      left: auto;
      height: 146px;
      top: 30px;
      width: 120px;
    }
    .swiper-button-prev {
      left: 0;
      right: auto;
      height: 146px;
      top: 30px;
      width: 120px;
    }

    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      color: var(--shipCove);
      font-size: 22px;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-next:after {
      color: var(--shipCove);
      font-size: 22px;
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 100px;
    width: 161px;
    bottom: 120px;

    .pagination-static {
      &::before {
        height: 70px;
        left: 81px;
      }

      .swiper-button-next {
        height: 100px;
        top: 22px;
        width: 80px;
      }
      .swiper-button-prev {
        height: 100px;
        top: 22px;
        width: 80px;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
    right: -27px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    height: 60px;
    bottom: -10px;
    width: 110px;
    right: 0px;

    .pagination-static {
      &::before {
        width: 2px;
        height: 40px;
        left: 54px;
        top: 14px;
      }
    }
    .pagination-static .swiper-button-prev,
    .pagination-static .swiper-button-next {
      top: 20px;
    }

    .pagination-static .swiper-button-prev {
      height: 60px;
      width: 50px;

      &:after {
        margin-top: 15px;
      }
    }

    .pagination-static .swiper-button-next {
      height: 60px;
      width: 50px;

      &:after {
        margin-top: 15px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }

  @media (max-width: 568px) {
  }
}

.slide-unit {
  max-width: 1110px;
  text-align: center;
  box-sizing: border-box;

  .icon-eye-mobile {
    display: none;
  }

  .header-units {
    text-align: left;
    padding: 10px 0 0 38px;

    h2 {
      font-weight: var(--weight-bold);
      font-size: 30px;
      line-height: 30px;
      color: var(--shipCove);
      margin-bottom: 8px;
    }

    h3 {
      font-weight: var(--weight-regular);
      font-size: 100px;
      line-height: 100px;
      color: var(--shipCove);
      margin-bottom: 32px;

      span {
        display: block;
        font-size: 34px;
        line-height: 34px;
      }
    }
  }

  .box-image-unit {
    .show-photos {
      position: relative;

      .icon-eye {
        width: 30px;
        cursor: pointer;
        position: absolute;
      }
    }

    &.box-image-unit_1 {
      .icon-eye_sala {
        top: 155px;
        left: 650px;
      }
      .icon-eye_quartosolteiro {
        top: 80px;
        left: 390px;
      }
      .icon-eye_quartocasal {
        top: 250px;
        left: 300px;
      }
      .icon-eye_cozinha {
        top: 210px;
        left: 790px;
      }
    }

    &.box-image-unit_2 {
      .icon-eye_sala {
        top: 220px;
        left: 700px;
      }
      .icon-eye_quartosolteiro {
        top: 350px;
        left: 440px;
      }
      .icon-eye_quartocasal {
        top: 180px;
        left: 340px;
      }
      .icon-eye_cozinha {
        top: 270px;
        left: 840px;
      }
    }

    &.box-image-unit_3 {
      .icon-eye_sala {
        top: 140px;
        left: 540px;
      }
      .icon-eye_quartosolteiro {
        top: 300px;
        left: 520px;
      }
      .icon-eye_quartocasal {
        top: 120px;
        left: 320px;
      }
      .icon-eye_cozinha {
        top: 170px;
        left: 765px;
      }
    }

    &.box-image-unit_4 {
      .icon-eye_sala {
        top: 330px;
        left: 580px;
      }
      .icon-eye_quartosolteiro {
        top: 140px;
        left: 600px;
      }
      .icon-eye_quartocasal {
        top: 330px;
        left: 820px;
      }
      .icon-eye_cozinha {
        top: 270px;
        left: 340px;
      }
    }

    &.box-image-unit_5 {
      .icon-eye_externa {
        top: 220px;
        left: 510px;
      }
      .icon-eye_1pav {
        top: 260px;
        left: 630px;
      }
    }

    &.box-image-unit_6 {
      .icon-eye_externa {
        top: 250px;
        left: 560px;
      }
      .icon-eye_1pav {
        top: 250px;
        left: 620px;
      }
    }
  }

  .button-doubleUnit {
    position: absolute;
    bottom: 185px;
    right: 500px;
    color: var(--blackRose);
    font-size: 20px;
    line-height: 20px;
    font-weight: var(--weight-semibold);
  }

  .button-compare {
    background: var(--blackRose);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 20px;
    line-height: 20px;
    border: none;
    border-radius: 100px;
    width: 150px;
    cursor: pointer;
    position: relative;
    z-index: 11;
  }

  .image-compare-unit_0 {
    max-height: 670px;
    margin-top: -20px;
  }

  .image-mapper-unit_0 {
    position: absolute;
    bottom: 150px;
    right: 330px;
  }

  .image-compare-unit_1 {
    max-height: 670px;
    margin-top: 0px;
  }

  .image-mapper-unit_1 {
    position: absolute;
    bottom: 140px;
    right: 330px;
  }

  .image-compare-unit_2 {
    max-height: 670px;
    margin-top: -40px;
  }

  .image-mapper-unit_2 {
    position: absolute;
    bottom: 130px;
    right: 330px;
  }

  .image-compare-unit_3 {
    max-height: 670px;
    margin-top: 20px;
  }

  .image-mapper-unit_3 {
    position: absolute;
    bottom: 130px;
    right: 330px;
  }

  .image-compare-unit_4 {
    max-height: 670px;
    margin-top: -40px;
  }

  .image-mapper-unit_4 {
    position: absolute;
    bottom: 140px;
    right: 330px;
  }

  .image-compare-unit_5 {
    max-height: 670px;
    margin-top: -60px;

    &.hide-image {
      display: none;
    }
  }

  .image-compare-doubleunit_5 {
    max-height: 670px;
    margin-top: 0px;
  }

  .image-mapper-unit_5 {
    position: absolute;
    bottom: 160px;
    right: 330px;
  }

  .image-compare-unit_6 {
    max-height: 670px;
    margin-top: -60px;

    &.hide-image {
      display: none;
    }
  }

  .image-compare-doubleunit_6 {
    max-height: 670px;
    margin-top: 50px;
  }

  .image-mapper-unit_6 {
    position: absolute;
    bottom: 160px;
    right: 330px;
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 800px;

    .header-units {
      h2 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 80px;
        line-height: 80px;
        margin-bottom: 14px;
      }
    }

    .box-image-unit {
      .show-photos {
        position: relative;

        .icon-eye {
          width: 30px;
          cursor: pointer;
          position: absolute;
        }
      }

      &.box-image-unit_1 {
        .icon-eye_sala {
          top: 60px;
          left: 470px;
        }
        .icon-eye_quartosolteiro {
          top: 30px;
          left: 280px;
        }
        .icon-eye_quartocasal {
          top: 180px;
          left: 200px;
        }
        .icon-eye_cozinha {
          top: 150px;
          left: 590px;
        }
      }

      &.box-image-unit_2 {
        .icon-eye_sala {
          top: 120px;
          left: 500px;
        }
        .icon-eye_quartosolteiro {
          top: 240px;
          left: 320px;
        }
        .icon-eye_quartocasal {
          top: 100px;
          left: 220px;
        }
        .icon-eye_cozinha {
          top: 190px;
          left: 620px;
        }
      }

      &.box-image-unit_3 {
        .icon-eye_sala {
          top: 80px;
          left: 390px;
        }
        .icon-eye_quartosolteiro {
          top: 240px;
          left: 380px;
        }
        .icon-eye_quartocasal {
          top: 80px;
          left: 220px;
        }
        .icon-eye_cozinha {
          top: 130px;
          left: 565px;
        }
      }

      &.box-image-unit_4 {
        .icon-eye_sala {
          top: 230px;
          left: 420px;
        }
        .icon-eye_quartosolteiro {
          top: 50px;
          left: 430px;
        }
        .icon-eye_quartocasal {
          top: 230px;
          left: 620px;
        }
        .icon-eye_cozinha {
          top: 160px;
          left: 200px;
        }
      }

      &.box-image-unit_5 {
        .icon-eye_externa {
          top: 120px;
          left: 370px;
        }
        .icon-eye_1pav {
          top: 150px;
          left: 430px;
        }
      }

      &.box-image-unit_6 {
        .icon-eye_externa {
          top: 180px;
          left: 360px;
        }
        .icon-eye_1pav {
          top: 150px;
          left: 420px;
        }
      }
    }

    .button-doubleUnit {
      bottom: 160px;
      right: 400px;
      font-size: 16px;
      line-height: 16px;
    }

    .button-compare {
      font-size: 14px;
      width: 120px;
      line-height: 14px;
      height: 30px;
    }

    .image-compare-unit_0 {
      max-height: 440px;
      margin-top: -40px;
    }

    .image-mapper-unit_0 {
      position: absolute;
      bottom: 140px;
      right: 230px;
    }

    .image-compare-unit_1 {
      max-height: 400px;
      margin-top: -20px;
    }

    .image-mapper-unit_1 {
      position: absolute;
      bottom: 120px;
      right: 230px;
    }

    .image-compare-unit_2 {
      max-height: 450px;
      margin-top: -60px;
    }

    .image-mapper-unit_2 {
      position: absolute;
      bottom: 110px;
      right: 230px;
    }

    .image-compare-unit_3 {
      max-height: 370px;
      margin-top: 20px;
    }

    .image-mapper-unit_3 {
      position: absolute;
      bottom: 110px;
      right: 230px;
    }

    .image-compare-unit_4 {
      max-height: 480px;
      margin-top: -100px;
    }

    .image-mapper-unit_4 {
      position: absolute;
      bottom: 110px;
      right: 230px;
    }

    .image-compare-unit_5 {
      max-height: 480px;
      margin-top: -120px;

      &.hide-image {
        display: none;
      }
    }

    .image-compare-doubleunit_5 {
      max-height: 400px;
      margin-top: -70px;
    }

    .image-mapper-unit_5 {
      position: absolute;
      bottom: 130px;
      right: 230px;
    }

    .image-compare-unit_6 {
      max-height: 420px;
      margin-top: -60px;

      &.hide-image {
        display: none;
      }
    }

    .image-compare-doubleunit_6 {
      max-height: 340px;
      margin-top: 10px;
    }

    .image-mapper-unit_6 {
      position: absolute;
      bottom: 130px;
      right: 230px;
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    .icon-eye-mobile {
      display: initial;
      cursor: pointer;
      position: absolute;
      left: 75px;
      height: 25px;
      width: 25px;
      top: 240px;
    }
    .show-photos {
      display: none;
    }
    .image-compare-unit_0,
    .image-compare-unit_1,
    .image-compare-unit_2,
    .image-compare-unit_3,
    .image-compare-unit_4,
    .image-compare-unit_5,
    .image-compare-unit_6 {
      max-height: 380px;
      margin-top: 10px;
    }

    .header-units {
      padding: 60px 0 0 60px;

      h2 {
        font-size: 20px;
        line-height: 20px;
      }

      h3 {
        font-size: 60px;
        line-height: 60px;

        span {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header-units {
      padding: 60px 0 0 0px;
    }

    .icon-eye-mobile {
      left: 0px;
    }
    .image-compare-unit_0,
    .image-compare-unit_1,
    .image-compare-unit_2,
    .image-compare-unit_3,
    .image-compare-unit_4,
    .image-compare-unit_5,
    .image-compare-unit_6,
    .image-compare-doubleunit_5,
    .image-compare-doubleunit_6 {
      max-height: 320px;
    }
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    .icon-eye-mobile {
      top: inherit;
      left: 65px;
      bottom: 120px;
    }
    .header-units {
      padding: 60px 0 0 90px;
    }
    .box-image-unit {
      max-width: 530px;
    }

    .image-compare-unit_0,
    .image-compare-unit_1,
    .image-compare-unit_2,
    .image-compare-unit_3,
    .image-compare-unit_4,
    .image-compare-unit_5,
    .image-compare-unit_6,
    .image-compare-doubleunit_5,
    .image-compare-doubleunit_6 {
      max-height: 200px;
      margin-top: 0px;
      max-width: 550px;
    }

    .image-mapper-unit_0,
    .image-mapper-unit_1,
    .image-mapper-unit_2,
    .image-mapper-unit_3,
    .image-mapper-unit_4,
    .image-mapper-unit_5,
    .image-mapper-unit_6 {
      max-width: 100px;
      top: 20px;
      right: 90px;
      bottom: initial;
      height: 70px;
    }

    .button-doubleUnit {
      bottom: initial;
      top: 105px;
      left: 27px;
      font-size: 14px;
    }

    .header-units {
      padding: 20px 0 0 60px;

      h2 {
        font-size: 16px;
        line-height: 16px;
      }

      h3 {
        font-size: 38px;
        line-height: 38px;

        span {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .button-compare {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    margin-top: 100px;
    max-width: 470px;

    .image-compare-unit_0,
    .image-compare-unit_1,
    .image-compare-unit_2,
    .image-compare-unit_3,
    .image-compare-unit_4,
    .image-compare-unit_5,
    .image-compare-unit_6,
    .image-compare-doubleunit_5,
    .image-compare-doubleunit_6 {
      max-height: 120px;
    }
    .button-doubleUnit {
      font-size: 9px;
      top: 102px;
      left: -45px;
    }
    .icon-eye-mobile {
      top: inherit;
      left: 45px;
      bottom: 120px;
    }
    .header-units {
      padding: 20px 0 0 40px;
    }
    .box-image-unit {
      max-width: 470px;
    }
  }

  @media (max-width: 568px) {
    .icon-eye-mobile {
      top: inherit;
      left: 15px;
      bottom: 120px;
    }
    .button-doubleUnit {
      top: 95px;
      left: -6px;
    }
    .header-units {
      padding: 10px 0 0 10px;
    }
    .box-image-unit {
      max-width: 370px;
    }
  }
}

.block-content {
  margin-top: 0px;
  max-width: 1110px;
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 800px;
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 1024px) {
    max-width: 610px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    position: fixed;
    min-height: 210px;
    right: 0;
    bottom: 10px;
    max-width: 470px;
  }
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.back-button {
  color: var(--white);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--white);
  }
}

.block-menu_secondary {
  a {
    color: var(--white);

    ::v-deep.icon-menu {
      stroke: var(--white);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--apricot);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
