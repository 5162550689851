<template>
  <layout-stage backgroundColor="#fcebc3" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockCards" :key="index">
            <div class="block-slide" :class="{ active: index === activeItem }">
              <img class="slider-image" alt="" :src="card.imageCard" />
              <div class="slider-content">
                <h3 @click="openCard(index)">
                  {{ card.title }}
                  <span>
                    <img alt="" src="~@/assets/images/icon-arrow-right2.svg"
                  /></span>
                </h3>
              </div>
              <div class="slider-infos">
                <h3>{{ card.infosTitle }}</h3>
                <img
                  alt=""
                  class="icon-close"
                  @click="closeCard()"
                  src="~@/assets/images/icon-close.svg"
                />
                <div class="slider-infos_box">
                  <p>{{ card.infosText }}</p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Project",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      MockCards: [
        {
          title: "Arquitetura",
          imageCard: require("@/assets/images/image-card-01.png"),
          infosTitle: "Torres Miranda",
          infosText:
            "Quando pensamos no projeto do Go Up, a ideia foi elaborar um edifício que atendesse às necessidades do cidadão moderno - aquele que quer morar perto de onde trabalha (e hoje, por que não, dentro dele), que quer as comodidades da cidade a poucos passos (e quando não estão perto, previmos o local para as entregas), que quer um lazer completo (mas sem exageros, sem comprometer o valor do condomínio). Os apartamentos têm plantas flexíveis, modernas, deixando o futuro morador aberto para sua deixá-lo com a sua cara.",
        },
        {
          title: "Paisagismo",
          imageCard: require("@/assets/images/image-card-02.png"),
          infosTitle: "Forma Garden",
          infosText:
            "Com localização privilegiada, o Go Up traz características especiais e diferenciadas, as quais tivemos a oportunidade de trabalhar por meio do paisagismo, de forma a valorizar ainda mais o empreendimento. Logo no encontro das ruas Bogari e Helianto, surge como um cartão de visitas do edifício e um ato de gentileza urbana uma grande praça, com generosa área verde trabalhada com plantas de alto valor ornamental, variadas formas, cores e florações. Essas foram dispostas no terreno de maneira a reforçar a simetria do espaço, dialogando com o projeto de arquitetura pré-existente. Além disso, foram inseridas árvores para o sombreamento dos espaços de estar e grandes palmeiras Imperiais que marcam a verticalidade do espaço. Subindo a rua Helianto, temos a entrada da parte residencial, onde um jardim com plantas tropicais exuberantes e folhagens diferenciadas foi desenhado com formas orgânicas, dando as boas vindas àqueles que entram no edifício e encantando os pedestres que por ali irão passar. Já para valorizar a área de lazer do empreendimento, foram trabalhadas plantas como as Palmeiras Catechu, que ao mesmo tempo que delimitam a entrada para a piscina trazem uma elegância à essa área externa, e as Dracenas Cordilines, que pontuam o espaço com sua bela folhagem avermelhada. Além disso, arbustos contornam os espaços de estar e lazer, criando pequenos microclimas que visam o aconchego e bem-estar dos moradores. Um belo jardim trazendo o contato com a natureza e todos seus benefício, isso é o que o paisagismo do Go Up irá proporcionar",
        },
        {
          title: "Interiores",
          imageCard: require("@/assets/images/image-card-03.png"),
          infosTitle: "Óbvio Arquitetura",
          infosText:
            "Utilizando-se de elementos e recursos que destacam traços modernos e contemporâneos, buscamos valorizar formas, equilibrar proporções e traduzir estilos de vida em um único espaço inspirador e criativo. Cores, texturas e volumetrias se convertem em um ambiente jovem, descontraído, despretensioso e imprevisível, contemplando não somente aspectos funcionais, mas também aqueles que tornam o lar aconchegante e, principalmente, único.",
        },
      ],
      // showCard: true,
      activeItem: null,
      swiperOptions: {
        slidesPerView: 2.5,
        spaceBetween: 24,
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  methods: {
    openCard(i) {
      this.activeItem = i;
    },

    closeCard() {
      this.activeItem = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 10px;
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 50px;
  max-width: 1300px;
  width: 100%;

  .block-slide {
    width: 494px;
    height: 732px;
    border-radius: 30px;
    position: relative;
    background-color: var(--tumbleweed);

    .slider-image {
      width: 491px;
      height: 731px;
      border-radius: 30px;
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 10;
      transition: all 200ms ease-in-out;
    }

    .slider-infos {
      width: 491px;
      height: 731px;
      border-radius: 30px;
      position: relative;
      background-color: var(--tumbleweed);
      opacity: 0;
      z-index: 9;
      transition: all 200ms ease-in-out;
      padding: 38px 20px 38px 20px;
      box-sizing: border-box;

      h3 {
        font-size: 30px;
        line-height: 39px;
        color: var(--blackRose);
        font-weight: var(--weight-bold);
        text-align: center;
        padding: 24px 0 0 0;
      }

      .icon-close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 32px;
        cursor: pointer;
      }

      .slider-infos_box {
        height: 601px;
        padding: 32px 28px 0 18px;

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--blackRose);
        }

        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--oasis);
        }
      }
    }

    .slider-content {
      position: absolute;
      bottom: 30px;
      left: 30px;
      max-width: 320px;
      width: 100%;
      z-index: 11;
      opacity: 1;
      transition: all 200ms ease-in-out;

      h3 {
        color: var(--oasis);
        font-size: 30px;
        line-height: 39px;
        font-weight: var(--weight-bold);
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          margin-left: 12px;
          margin-top: 6px;
          img {
            width: 32px;
            height: 32px;
            position: relative;
          }
        }
      }
    }

    &.active {
      .slider-content {
        opacity: 0;
        z-index: 8;
        transition: all 200ms ease-out;
      }

      .slider-image {
        z-index: 9;
        bottom: -1px;
        left: 0px;
        transition: all 200ms ease-out;
      }

      .slider-infos {
        opacity: 1;
        z-index: 10;
        bottom: 10px;
        left: 10px;
        transition: all 200ms ease-in;
      }
    }
  }

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 350px;
      height: 500px;

      .slider-image {
        width: 380px;
        height: 500px;
      }

      .slider-infos {
        width: 350px;
        height: 500px;

        h3 {
          font-size: 24px;
          line-height: 34px;
        }

        .icon-close {
          top: 24px;
          right: 24px;
          width: 22px;
        }

        .slider-infos_box {
          height: 380px;

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .slider-content {
        h3 {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    position: fixed;
    right: 0;
    bottom: 10px;
    max-width: 530px;
    margin-top: 20px;

    .block-slide {
      width: 190px;
      height: 260px;
      border-radius: 20px;

      .slider-image {
        width: 190px;
        height: 260px;
        bottom: 5px;
        left: 5px;
        border-radius: 20px;
      }

      .slider-infos {
        width: 195px;
        height: 260px;
        padding: 10px;

        h3 {
          font-size: 14px;
          line-height: 24px;
        }

        .icon-close {
          top: 18px;
          right: 18px;
          width: 14px;
        }

        .slider-infos_box {
          height: 180px;
          padding: 12px 22px 0 22px;

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .slider-content {
        bottom: 15px;
        left: 23px;
        max-width: 170px;

        h3 {
          font-size: 12px;
          line-height: 16px;

          span {
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 500px;
    margin-top: 20px;

    .block-slide {
      width: 160px;
      height: 185px;
      border-radius: 20px;

      .slider-image {
        width: 160px;
        height: 185px;
        object-fit: cover;
        bottom: 5px;
        left: 5px;
        border-radius: 20px;
      }

      .slider-infos {
        width: 160px;
        height: 185px;
        padding: 10px;

        h3 {
          font-size: 14px;
          line-height: 24px;
        }

        .icon-close {
          top: 18px;
          right: 18px;
          width: 14px;
        }

        .slider-infos_box {
          height: 110px;
          padding: 12px 22px 0 12px;

          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;
    margin-top: 20px;

    .block-slide {
      width: 150px;
      height: 165px;

      .slider-image {
        width: 150px;
        height: 165px;
      }

      .slider-infos {
        width: 140px;
        height: 165px;
        padding: 10px;
        border-radius: 20px;

        .slider-infos_box {
          height: 80px;
          padding: 12px 22px 0 12px;
        }
      }
    }
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--tumbleweed);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
