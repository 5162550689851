<template>
  <layout-stage backgroundColor="#fcebc3" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="implantation">
          <div class="implantation_box">
            <div class="implantation_infos">
              <ul>
                <li v-for="(item, index) in mockImplantation" :key="index">
                  <span
                    v-if="item.imageModal"
                    class="clickable"
                    @click="openPhoto(item.imageModal)"
                  >
                    <img :src="item.image" alt="" />
                    {{ item.name }}
                  </span>

                  <span v-else>
                    <img :src="item.image" alt="" />
                    {{ item.name }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="image-map_box">
          <img
            class="icon-eye-mobile"
            src="~@/assets/images/icon-eye.svg"
            @click="openModalPhotosMobile(mockImplantation)"
          />
          <div class="image-map_points">
            <div
              v-for="(item, index) in mockImplantation"
              :key="index"
              :class="['pin pin_' + index]"
            >
              <img
                v-if="item.imageModal"
                class="
                  clickable
                  animate__animated
                  animate__headShake
                  animate__delay-2s
                  animate__infinite
                "
                @click="openPhoto(item.imageModal)"
                :src="item.imageMap"
                alt=""
              />
              <img v-else :src="item.imageMap" alt="" />
            </div>
          </div>
          <img
            class="image-implantation"
            src="~@/assets/images/image-implantation-map.png"
            alt=""
          />
        </div>
      </section>

      <modal-photo
        :photo="openPhotoSelected"
        v-if="showModalPhotos"
        @close="showModalPhotos = false"
      />

      <modal-multiple-photos
        :photos="openPhotosMobileSelected"
        v-if="showModalPhotosMobile"
        @close="showModalPhotosMobile = false"
      />
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import ModalPhoto from "@/components/ModalPhoto";
import ModalMultiplePhotos from "@/components/ModalMultiplePhotos";

export default {
  name: "ProjectImplantation",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    ModalPhoto,
    ModalMultiplePhotos,
  },

  data() {
    return {
      showModalPhotos: false,
      openPhotoSelected: "",
      openPhotosMobileSelected: [],
      showModalPhotosMobile: false,
      mockImplantation: [
        {
          image: require("@/assets/images/icon-implantation-01.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-01.svg"),
          name: "Copa",
        },
        {
          image: require("@/assets/images/icon-implantation-02.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-02.svg"),
          name: "I.S.P.C.D",
        },
        {
          image: require("@/assets/images/icon-implantation-03.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-03.svg"),
          name: "Toucador",
        },
        {
          image: require("@/assets/images/icon-implantation-04.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-04.svg"),
          name: "Go Meet",
          imageModal: require("@/assets/images/image-implantation-04.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-05.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-05.svg"),
          name: "Go  Party",
          imageModal: require("@/assets/images/image-implantation-05.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-06.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-06.svg"),
          name: "Go Lounge",
        },
        {
          image: require("@/assets/images/icon-implantation-07.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-07.svg"),
          name: "Go Barbecue",
          imageModal: require("@/assets/images/image-implantation-07.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-08.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-08.svg"),
          name: "Go Health",
          imageModal: require("@/assets/images/image-implantation-08.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-09.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-09.svg"),
          name: "Go Relax",
        },
        {
          image: require("@/assets/images/icon-implantation-10.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-10.svg"),
          name: "Ducha",
        },
        {
          image: require("@/assets/images/icon-implantation-11.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-11.svg"),
          name: "Go Pet",
          imageModal: require("@/assets/images/image-implantation-11.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-12.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-12.svg"),
          name: "Go  Swim",
          imageModal: require("@/assets/images/image-implantation-12.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-13.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-13.svg"),
          name: "Go Sports",
          imageModal: require("@/assets/images/image-implantation-13.png"),
        },
        {
          image: require("@/assets/images/icon-implantation-14.svg"),
          imageMap: require("@/assets/images/icon-map-implantation-14.svg"),
          name: "Go Fire",
          imageModal: require("@/assets/images/image-implantation-14.png"),
        },
      ],
    };
  },

  methods: {
    openPhoto(photoUrl) {
      this.openPhotoSelected = photoUrl;
      this.showModalPhotos = true;
    },
    openModalPhotosMobile(photos) {
      this.openPhotosMobileSelected = photos.map(function (item) {
        if (item.imageModal) {
          return item.imageModal;
        }
      });
      this.showModalPhotosMobile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .image-map_box {
    position: relative;

    .icon-eye-mobile {
      display: none;
    }

    .image-map_points {
      img {
        width: 40px;

        &.clickable {
          cursor: pointer;
        }
      }

      .pin {
        position: absolute;
      }

      .pin_0 {
        top: 520px;
        right: 200px;
      }

      .pin_1 {
        top: 565px;
        right: 268px;
      }

      .pin_2 {
        top: 550px;
        right: 328px;
      }

      .pin_3 {
        top: 510px;
        right: 260px;
      }

      .pin_4 {
        top: 640px;
        right: 220px;
      }

      .pin_5 {
        top: 610px;
        right: 330px;
      }

      .pin_6 {
        top: 483px;
        right: 450px;
      }

      .pin_7 {
        top: 390px;
        right: 520px;
      }

      .pin_8 {
        top: 383px;
        right: 625px;
      }

      .pin_9 {
        top: 445px;
        right: 580px;
      }

      .pin_10 {
        top: 485px;
        right: 630px;
      }

      .pin_11 {
        top: 550px;
        right: 464px;
      }

      .pin_12 {
        top: 435px;
        right: 254px;
      }

      .pin_13 {
        top: 490px;
        right: 370px;
      }
    }
  }

  .image-implantation {
    object-fit: cover;
    max-width: 800px;
    height: 600px;
    border-radius: 30px 0 0 0;
    margin: 160px 20px 0 0;
  }

  .implantation {
    margin-top: 50px;
    .implantation_box {
      padding: 57px;
      background-color: var(--tumbleweed);
      height: 767px;
      width: 488px;
      border-radius: 30px;
      .implantation_infos {
        overflow-y: auto;
        height: 650px;

        ul {
          li {
            font-size: 24px;
            line-height: 30px;
            color: var(--blackRose);
            margin-bottom: 32px;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
              width: 100%;
              cursor: default;

              &.clickable {
                cursor: pointer;
              }
            }

            img {
              width: 47px;
              margin-right: 21px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--oasis);
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-map_box {
      position: relative;

      .image-map_points {
        img {
          width: 26px;
        }

        .pin_0 {
          top: 360px;
          right: 144px;
        }

        .pin_1 {
          top: 393px;
          right: 185px;
        }

        .pin_2 {
          top: 380px;
          right: 230px;
        }

        .pin_3 {
          top: 350px;
          right: 184px;
        }

        .pin_4 {
          top: 438px;
          right: 155px;
        }

        .pin_5 {
          top: 422px;
          right: 225px;
        }

        .pin_6 {
          top: 333px;
          right: 314px;
        }

        .pin_7 {
          top: 275px;
          right: 350px;
        }

        .pin_8 {
          top: 270px;
          right: 425px;
        }

        .pin_9 {
          top: 310px;
          right: 394px;
        }

        .pin_10 {
          top: 338px;
          right: 430px;
        }

        .pin_11 {
          top: 385px;
          right: 314px;
        }

        .pin_12 {
          top: 300px;
          right: 184px;
        }

        .pin_13 {
          top: 340px;
          right: 254px;
        }
      }
    }

    .image-implantation {
      max-width: 520px;
      height: 400px;
      margin: 120px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 38px;
        height: 500px;
        width: 350px;

        .implantation_infos {
          height: 420px;

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 20px;

              img {
                width: 32px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    .image-map_box {
      .icon-eye-mobile {
        display: initial;
        cursor: pointer;
        position: absolute;
        left: 190px;
        height: 35px;
        width: 35px;
        top: 240px;
      }
      .image-map_points {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;

    .image-map_box {
      .icon-eye-mobile {
        left: 80px;
        top: 140px;
      }
    }

    .image-implantation {
      max-width: 240px;
      height: 180px;
      margin: 70px 20px 0 0;
    }

    .implantation {
      margin-top: 10px;

      .implantation_box {
        padding: 24px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .implantation_infos {
          height: 220px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-implantation {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 440px;

    .image-map_box {
      .icon-eye-mobile {
        left: 80px;
        top: 90px;
      }
    }

    .image-implantation {
      max-width: 220px;
      height: 170px;
      margin: 30px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 24px;
        height: 180px;
        width: 230px;
        margin-right: 20px;

        .implantation_infos {
          height: 130px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 440px;

    .image-map_box {
      .icon-eye-mobile {
        left: 60px;
        top: 90px;
      }
    }

    .image-implantation {
      max-width: 220px;
      height: 120px;
      margin: 50px 20px 0 0;
    }

    .implantation {
      .implantation_box {
        padding: 24px;
        height: 185px;
        width: 200px;
        margin-right: 20px;

        .implantation_infos {
          height: 135px;

          p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--tumbleweed);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
