<template>
  <div class="home">
    <header class="animate__animated animate__fadeInDown">
      <img
        class="logo-goup animate__animated animate__fadeIn"
        alt="GoUp Logo"
        src="~@/assets/images/logo-goup.svg"
      />
      <img
        class="logo-riv animate__animated animate__fadeIn"
        alt="Riv Logo"
        src="~@/assets/images/logo-riv.svg"
      />
    </header>
    <main>
      <main-menu />

      <section class="block-youtube animate__animated animate__bounceInRight">
        <img
          class="block-youtube_bg"
          alt=""
          src="~@/assets/images/image-home-yt.png"
        />
        <a class="block-youtube_button" @click="isModalHomeActive = true">
          <span
            class="
              animate__animated
              animate__pulse
              animate__delay-1s
              animate__infinite
            "
          >
            <img alt="" src="~@/assets/images/icon-play.svg" />
          </span>
          Ver vídeo
        </a>
        <p>A leveza de viver bem.</p>
      </section>
    </main>

    <b-modal
      :destroy-on-hide="true"
      class="home-modal"
      v-model="isModalHomeActive"
    >
      <div class="modal-yt">
        <div class="modal-yt_header">
          <img
            class="logo-goup"
            alt="GoUp Logo"
            src="~@/assets/images/logo-goup.svg"
          />
          <img
            class="logo-riv"
            alt="Riv Logo"
            src="~@/assets/images/logo-riv.svg"
          />
        </div>
        <div class="modal-yt_main">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/601104514?h=a6b8561650"
            width="640"
            height="360"
            frameborder="0"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </b-modal>

    <b-modal
      :destroy-on-hide="true" 
      :active="true"
      class="home-modal"
      @close="openFullScreen()"
      v-model="isFullScreenActive"
    >
      <div class="modal-yt">
        <div class="modal-yt_main">
          <div class="data-sheet_box">
              <h3>Bem-vindo</h3>
              <div class="slider-infos_box">
                <p>Para uma melhor navegação é necessário 
                    iniciar a aplicaçã em modo full screen!</p>
                <button
                  @click="openFullScreen()"
                  class="button-full-screen"
                >
                  Iniciar navegação
                </button>
              </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: "Home",

  components: {
    MainMenu,
  },

  data() {
    return {
      isModalHomeActive: false,
      isFullScreenActive: this.checkFullScreen(),
    };
  },
   methods: {
    openFullScreen() {
       var elem = document.documentElement;
       if (elem.requestFullscreen) { /* Chrome */
          elem.requestFullscreen();
       } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
       } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
       } else if (elem.mozFullScreenElement) { /* Moz */
          elem.mozFullScreenElement();
       }
       this.isFullScreenActive = false
    },
    checkFullScreen() {
       var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || 
       document.webkitFullscreenElement || document.msFullscreenElement
       return !fullscreenElement
    }
   }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/modal";

.home {
  background: url("~@/assets/images/bg-home-circles.svg") bottom right no-repeat,
    url("~@/assets/images/bg-home-right.svg") bottom right no-repeat,
    url("~@/assets/images/bg-home-left.svg") bottom left no-repeat,
    url("~@/assets/images/bg-home.jpg") bottom right no-repeat;
  background-size: contain, contain, cover, cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  header {
    padding: 32px 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    img.logo-goup {
      display: block;
      width: 240px;
      height: auto;
    }

    img.logo-riv {
      display: block;
      width: 176px;
      height: auto;
    }
  }

  main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 100px;
    width: 100%;
    justify-content: space-between;
    flex: 1;

    .block-youtube {
      position: relative;
      padding-right: 40px;
      margin: 130px 140px 0 0;

      .block-youtube_button {
        position: absolute;
        left: 190px;
        top: 260px;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-weight: var(--weight-semibold);
        font-size: 20px;
        line-height: 20px;

        span {
          background-color: var(--tumbleweed);
          border-radius: 100%;
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
        }
      }

      p {
        font-weight: var(--weight-semibold);
        color: var(--white);
        font-size: 60px;
        line-height: 97.9%;
        max-width: 250px;
        position: absolute;
        bottom: -30px;
        right: -40px;
        z-index: 9;
      }

      .block-youtube_bg {
        position: relative;
        z-index: 8;
        max-width: 577px;
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 1680px) {
    main .block-youtube {
      margin: 0 0 0 0;
    }
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    header {
      padding: 32px 30px;
    }

    main {
      padding: 0 30px;

      .block-youtube {
        max-width: 500px;
        .block-youtube_button {
          left: 150px;
          top: 205px;
        }
        p {
          right: -10px;
          bottom: -90px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    background: url("~@/assets/images/bg-home-circles.svg") bottom right
        no-repeat,
      url("~@/assets/images/bg-home-right.svg") bottom right no-repeat,
      url("~@/assets/images/bg-home-left.svg") bottom left no-repeat,
      url("~@/assets/images/bg-home.jpg") bottom right no-repeat;
    background-size: contain, contain, cover, 750px 400px;
    header {
      padding: 20px 30px;

      img.logo-goup,
      img.logo-riv {
        width: 130px;
      }
    }

    main {
      padding: 0 30px;

      .block-youtube {
        max-width: 300px;
        margin-top: 40px;
        .block-youtube_bg {
          max-width: 300px;
          right: -20px;
          top: -20px;
        }
        .block-youtube_button {
          left: 100px;
          top: 95px;
          font-size: 12px;

          span {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            img {
              height: 12px;
            }
          }
        }
        p {
          right: 10px;
          bottom: 45px;
          font-size: 20px;
          line-height: 22px;
          max-width: 90px;
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    background-size: contain, contain, cover, 750px 400px;
    main {
      .block-youtube {
        margin-top: -20px;
      }
    }
  }
}

.modal-yt {
  max-width: 1100px;
  width: 100%;

  .modal-yt_header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;

    .logo-goup {
      width: 134px;
      height: auto;
      margin-right: 38px;
    }

    .logo-riv {
      width: 117px;
      height: auto;
      margin-left: 38px;
    }
  }

  .modal-yt_main {
    iframe {
      border-radius: 30px;
      width: 100%;
      height: 550px;
    }

    .data-sheet_box {
      padding: 57px;
      background-color: var(--tumbleweed);
      height: 434px;
      width: 549px;
      margin: auto;
      border-radius: 30px;
     
      h3 {
        font-size: 30px;
        line-height: 39px;
        color: var(--blackRose);
        font-weight: var(--weight-bold);
        text-align: center;
        padding: 34px 0 0 0;
      }

      .slider-infos_box {
        padding: 32px 18px 0 18px;

        p {
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          color: var(--blackRose);
        }

        .button-full-screen {
          background: var(--blackRose);
          height: 50px;
          display: flex;
          margin: auto;
          top: 52px;
          align-items: center;
          justify-content: center;
          color: var(--white);
          font-size: 16px;
          line-height: 20px;
          border: none;
          border-radius: 100px;
          width: 270px;
          cursor: pointer;
          position: relative;
          z-index: 11;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    max-width: 600px;

    .modal-yt_main {
      iframe {
        height: 280px;
      }
      
      .data-sheet_box {
        padding: 17px;
        margin: auto;
        background-color: var(--tumbleweed);
        height: 170px;
        width: 300px;
        border-radius: 30px;
      
        h3 {
          font-size: 18px;
          line-height: 39px;
          color: var(--blackRose);
          font-weight: var(--weight-bold);
          text-align: center;
          padding: 4px 0 0 0;
        }

        .slider-infos_box {
          padding: 8px 18px 0 18px;

          p {
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: var(--blackRose);
          }

          .button-full-screen {
            background: var(--blackRose);
            height: 22px;
            display: flex;
            margin: auto;
            top: 20px;
            align-items: center;
            justify-content: center;
            color: var(--white);
            font-size: 10px;
            line-height: 20px;
            border: none;
            border-radius: 100px;
            width: 140px;
            cursor: pointer;
            position: relative;
            z-index: 11;
          }
        }
      }
    }
  }
}
</style>
