<template>
  <b-modal :destroy-on-hide="true" :active="true" class="compare-modal">
    <div class="compare-modal_content">
      <img
        class="button-close"
        alt=""
        src="~@/assets/images/icon-close-modal.svg"
        @click="$emit('close')"
      />
      <div class="columns">
        <div class="column">
          <div class="box-compare box-compare-selected">
            <h2>{{ activeUnit.name }}</h2>
            <h3>{{ activeUnit.meters }}</h3>
            <div class="image-unit_box">
              <img class="image-unit" :src="activeUnit.imageUnit" alt="" />
            </div>
            <img class="image-mapper" :src="activeUnit.imageMapper" alt="" />
          </div>
        </div>
        <div class="column">
          <div class="box-compare box-compare-units">
            <swiper class="swiper" ref="compareSwiper" :options="swiperOption">
              <swiper-slide
                v-for="(unit, index) in initialCarouselUnits"
                :key="index"
              >
                <h2>{{ unit.name }}</h2>
                <h3>{{ unit.meters }}</h3>
                <div class="image-unit_box">
                  <img
                    :class="[
                      'image-unit',
                      unit.imageUnitDouble &&
                      openDoubleImage &&
                      index === setIndex
                        ? 'hide-image'
                        : '',
                    ]"
                    :src="unit.imageUnit"
                    alt=""
                  />
                  <img
                    v-if="openDoubleImage && index === setIndex"
                    :class="image - unit"
                    :src="unit.imageUnitDouble"
                    alt=""
                  />
                  <a
                    @click="openImageUnitDouble(index)"
                    v-if="unit.imageUnitDouble && !openDoubleImage"
                    class="button-doubleUnit"
                    >Ver 2º pavimento</a
                  >
                  <a
                    @click="openImageUnitDouble(index)"
                    v-if="unit.imageUnitDouble && openDoubleImage"
                    class="button-doubleUnit"
                    >Ver 1º pavimento</a
                  >
                </div>
                <img class="image-mapper" :src="unit.imageMapper" alt="" />
              </swiper-slide>
            </swiper>
            <div class="static-pagination">
              <button
                v-for="(unit, index) in initialCarouselUnits"
                :key="index"
                class="pagination-button"
                :class="{
                  active: index === setIndex,
                }"
                @click="goSlider(index)"
              >
                <span> </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ModalCompare",

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    activeUnit: {
      type: Object,
      required: true,
    },
    carouselUnits: {
      type: Array,
      required: true,
    },
    indexActiveUnit: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.initialCarouselUnits = [
      ...this.carouselUnits.slice(0, this.indexActiveUnit),
      ...this.carouselUnits.slice(this.indexActiveUnit + 1),
    ];
  },

  data() {
    return {
      setIndex: 0,
      initialCarouselUnits: [],
      openDoubleImage: false,
      swiperOption: {
        direction: "vertical",
        simulateTouch: false,
      },
    };
  },

  methods: {
    goSlider(i) {
      this.setIndex = i;
      this.$refs.compareSwiper.$swiper.slideTo(i, 1000, false);
    },
    openImageUnitDouble(i) {
      this.openDoubleImage = !this.openDoubleImage;
      this.setIndex = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-close {
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.compare-modal {
  ::v-deep.modal-close {
    display: none;
  }

  ::v-deep.modal-content {
    overflow: initial;
    max-width: 1600px !important;

    //Responsive mode

    @media (max-width: 1680px) {
    }

    @media (max-width: 1440px) {
      max-width: 1200px !important;
    }

    @media (max-width: 1366px) {
    }

    @media (max-width: 1200px) {
      max-width: 900px !important;
    }

    @media (max-width: 1024px) {
    }
  }

  ::v-deep.swiper-slide {
    &.hide {
      display: none;
    }
  }

  .static-pagination {
    position: absolute;
    top: 20px;
    right: -40px;
    display: flex;
    flex-direction: column;

    .pagination-button {
      background: var(--oasis);
      width: 23px;
      height: 23px;
      border-radius: 100%;
      border: none;
      outline: none;
      margin: 3px 0;
      cursor: pointer;

      &.hide {
        display: none;
      }

      &.active {
        background: var(--apricot);
      }
    }

    //Responsive mode

    @media (max-width: 1680px) {
    }

    @media (max-width: 1440px) {
      right: -30px;
      .pagination-button {
        width: 13px;
        height: 13px;
      }
    }

    @media (max-width: 1366px) {
    }

    @media (max-width: 1200px) {
    }

    @media (max-width: 1024px) {
    }
  }

  .columns {
    max-height: 800px;
    position: relative;

    //Responsive mode

    @media (max-width: 1680px) {
    }

    @media (max-width: 1440px) {
      max-height: 600px;
    }

    @media (max-width: 1366px) {
    }

    @media (max-width: 1200px) {
    }

    @media (max-width: 1024px) {
    }
  }

  .box-compare {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--apricot);
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    .image-mapper {
      max-width: 120px;
      height: auto;
      position: absolute;
      bottom: 50px;
      right: 70px;
    }

    h2 {
      font-weight: var(--weight-bold);
      font-size: 32px;
      line-height: 32px;
      color: var(--shipCove);
      margin-bottom: 12px;
    }

    h3 {
      font-weight: var(--weight-regular);
      font-size: 100px;
      line-height: 100px;
      color: var(--shipCove);

      span {
        display: block;
        font-size: 34px;
        line-height: 34px;
      }
    }

    .button-doubleUnit {
      position: absolute;
      bottom: 10px;
      color: var(--blackRose);
      font-size: 16px;
      line-height: 16px;
      font-weight: var(--weight-semibold);
    }

    &.box-compare-selected {
      border-radius: 0 110px 110px 110px;
    }

    &.box-compare-units {
      border-radius: 110px 0 110px 110px;

      h2,
      h3 {
        text-align: right;
      }

      .image-mapper {
        bottom: 0;
        right: 0;
      }
    }

    .image-unit_box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 500px;

      .image-unit {
        max-height: 420px;
        width: auto;

        &.hide-image {
          display: none;
        }
      }
    }

    //Responsive mode

    @media (max-width: 1680px) {
    }

    @media (max-width: 1440px) {
      .image-mapper {
        max-width: 80px;
        bottom: 30px;
        right: 30px;
      }

      h2 {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 8px;
      }

      h3 {
        font-size: 48px;
        line-height: 48px;
      }

      .image-unit_box {
        height: 400px;

        .image-unit {
          max-height: 320px;
        }
      }
    }

    @media (max-width: 1366px) {
    }

    @media (max-width: 1200px) {
    }

    @media (max-width: 1024px) {
    }
  }

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }
}
</style>
