<template>
  <layout-stage backgroundColor="#de9480" themeName="common_areas">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'CommonAreas' }" exact>
              <icon-home />
              Residencial
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CommonAreasNeighborhood' }" exact>
              <icon-neighborhood />
              Vizinhança
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(card, index) in MockPhoto" :key="index">
            <div class="block-slide">
              <img class="slider-image" alt="" :src="card.image" />
              <div class="slider-content">
                <h3>{{ card.title }}</h3>
              </div>
            </div>
          </swiper-slide>

          <div
            @click="prevSwiper()"
            class="swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            @click="nextSwiper()"
            class="swiper-button-next"
            slot="button-next"
          ></div>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconHome from "@/components/icons/IconHome";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "CommonAreasNeighborhood",

  components: {
    LayoutStage,
    IconBack,
    IconHome,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      MockPhoto: [
        {
          title: "Mc Donald's",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-01.png"),
        },
        {
          title: "Colégio ICJ",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-02.png"),
        },
        {
          title: "Drogaria Araujo",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-03.png"),
        },
        {
          title: "Unimed",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-04.png"),
        },
        {
          title: "Faculdade Newton Paiva",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-05.png"),
        },
        {
          title: "Super Nosso",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-06.png"),
        },
        {
          title: "Banco do Brasil",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-07.png"),
        },
        {
          title: "Cefet-MG",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-08.png"),
        },
        {
          title: "Academia SmartFit",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-09.png"),
        },
        {
          title: "Academia SmartFit",
          image: require("@/assets/images/areas-comuns/area-comum-vizinhaca-10.png"),
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  methods: {
    prevSwiper() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    nextSwiper() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 44px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--oasis);
  font-size: 22px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-next:after {
  color: var(--oasis);
  font-size: 22px;
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1311px;
  width: 100%;

  .block-slide {
    width: 1207px;
    height: 755px;
    border-radius: 30px;
    position: relative;
    background-color: var(--oasis);

    .slider-image {
      width: 1207px;
      height: 755px;
      border-radius: 30px;
      position: absolute;
      object-fit: cover;
      bottom: 20px;
      left: 10px;
    }

    .slider-content {
      position: absolute;
      bottom: -50px;
      left: 275px;
      width: 100%;

      h3 {
        color: var(--oasis);
        font-size: 30px;
        line-height: 30px;
        font-weight: var(--weight-regular);
        margin-bottom: 5px;
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .block-slide {
      width: 800px;
      height: 570px;

      .slider-image {
        width: 800px;
        height: 570px;
      }

      .slider-content {
        left: 175px;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--blackRose);

    ::v-deep.icon-menu {
      stroke: var(--blackRose);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--oasis);
      background: var(--blackRose);

      ::v-deep.icon-menu {
        stroke: var(--oasis);
      }
    }
  }
}
</style>
