<template>
  <layout-stage backgroundColor="#5c2e3d" themeName="about">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'About' }" exact>
              <icon-store />
              Conveniências
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AboutPhotos' }" exact>
              <icon-square />
              Fotos interativas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'About360' }" exact>
              <icon-compass />
              Vista 360º
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <div class="image-interactive">
          <img
            src="~@/assets/images/icon-goup.svg"
            alt=""
            class="pointer-goup"
          />
          <div class="list-points">
            <ul>
              <li v-for="(item, index) in listPoints" :key="index">
                <button
                  class="button-interactive"
                  :class="{ active: index === activeItem }"
                  @click="selectItem(index)"
                >
                  {{ item.name }}
                </button>
                <div
                  :class="[
                    'pointer pointer-' + index,
                    index === activeItem
                      ? 'animate__animated animate__wobble animate__infinite'
                      : '',
                  ]"
                >
                  <img :src="setIcon(item.type)" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconCompass from "@/components/icons/IconCompass";
import IconSquare from "@/components/icons/IconSquare";
import IconStore from "@/components/icons/IconStore";

export default {
  name: "AboutPhotos",

  components: {
    LayoutStage,
    IconBack,
    IconCompass,
    IconSquare,
    IconStore,
  },

  data() {
    return {
      listPoints: [
        { type: "street", name: "R. Bogari" },
        { type: "street", name: "Av. Amazonas" },
        { type: "street", name: "R. Helianto" },
        { type: "pin", name: "C. Vendas Riva" },
        { type: "pin", name: "Gameleira" },
        { type: "pin", name: "Smart Fit" },
        { type: "bank", name: "Banco do Brasil" },
      ],
      activeItem: null,
    };
  },

  methods: {
    setIcon(type) {
      const types = {
        street: require("@/assets/images/icon-street.svg"),
        market: require("@/assets/images/icon-market.svg"),
        pin: require("@/assets/images/icon-pin.svg"),
        bank: require("@/assets/images/icon-bank.svg"),
      };

      return types[type.toLowerCase()] ?? "Type not found";
    },

    selectItem(i) {
      this.activeItem = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 0px;
  max-width: 1311px;
  width: 100%;
  height: 92vh;
  display: flex;
  flex: 1;
  position: relative;
  align-items: flex-start;

  @media (max-width: 1680px) {
    max-width: 1191px;
  }

  @media (max-width: 1440px) {
    max-width: 951px;
  }

  @media (max-width: 1366px) {
    max-width: 881px;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  .image-interactive {
    margin-top: 60px;
    background: url("~@/assets/images/bg-about-interactive.png") top center
      no-repeat;
    background-size: 1311px 895px;
    border-radius: 30px 0 0 0;
    height: 100%;
    display: flex;
    flex: 1;

    .pointer-goup {
      position: absolute;
      top: 330px;
      left: 568px;
    }

    @media (max-width: 1680px) {
      background-size: 1191px 813px;
    }

    @media (max-width: 1440px) {
      margin-top: 0px;
      background-size: 951px 674px;
      .pointer-goup {
        top: 170px;
        left: 395px;
      }
    }

    @media (max-width: 1366px) {
      background-size: 881px 699px;
      .pointer-goup {
        top: 175px;
        left: 360px;
      }
    }

    @media (max-width: 1200px) {
    }

    @media (max-width: 1024px) {
    }

    .list-points {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      width: 100%;
      height: 85%;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        li {
          display: inline-block;
          margin: 0 5px;

          .pointer {
            position: absolute;
            top: 10px;
            left: 10px;

            &.pointer-0 {
              top: 550px;
              left: 660px;
            }

            &.pointer-1 {
              top: 450px;
              left: 910px;
            }

            &.pointer-2 {
              top: 550px;
              left: 520px;
            }

            &.pointer-3 {
              top: 605px;
              left: 700px;
            }

            &.pointer-4 {
              top: 260px;
              left: 230px;
            }

            &.pointer-5 {
              top: 570px;
              left: 1130px;
            }

            &.pointer-6 {
              top: 610px;
              left: 130px;
            }

            @media (max-width: 1680px) {
            }

            @media (max-width: 1440px) {
              width: 60px;
              height: auto;

              &.pointer-0 {
                top: 370px;
                left: 480px;
              }

              &.pointer-1 {
                top: 280px;
                left: 660px;
              }

              &.pointer-2 {
                top: 370px;
                left: 380px;
              }

              &.pointer-3 {
                top: 415px;
                left: 507px;
              }

              &.pointer-4 {
                top: 150px;
                left: 170px;
              }

              &.pointer-5 {
                top: 475px;
                left: 830px;
              }

              &.pointer-6 {
                top: 420px;
                left: 100px;
              }
            }

            @media (max-width: 1366px) {
              &.pointer-0 {
                top: 390px;
                left: 440px;
              }

              &.pointer-1 {
                top: 285px;
                left: 620px;
              }

              &.pointer-2 {
                top: 390px;
                left: 350px;
              }

              &.pointer-3 {
                top: 430px;
                left: 470px;
              }

              &.pointer-4 {
                top: 160px;
                left: 153px;
              }

              &.pointer-5 {
                top: 405px;
                left: 760px;
              }

              &.pointer-6 {
                top: 440px;
                left: 90px;
              }
            }

            @media (max-width: 1200px) {
            }

            @media (max-width: 1024px) {
            }
          }

          .button-interactive {
            border: none;
            height: 40px;
            padding: 13px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 14px;
            color: var(--blackRose);
            background: var(--oasis);
            cursor: pointer;
            transition: all 200ms ease-in-out;
            border-radius: 50px;

            &:hover,
            &:focus,
            &:active,
            &.active {
              color: var(--oasis);
              transition: all 200ms ease-in-out;
              background: var(--blackRose);
            }
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--oasis);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--oasis);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--oasis);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }

  @media (max-width: 1200px) {
    li:nth-child(2) {
      display: none;
    }
  }
}
</style>
