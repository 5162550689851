<template>
  <layout-stage backgroundColor="#fcebc3" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="slide">
            <div class="differentials">
              <div class="differentials_box">
                <img
                  class="differentials-logo"
                  src="~@/assets/images/logo-goeasy.svg"
                  alt=""
                />
                <div class="differentials_infos">
                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-elevator.svg"
                      alt=""
                    />
                    <span
                      >2 elevadores na torre, cada um atendendo apenas 3
                      unidades do andar</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-infrastructure.svg"
                      alt=""
                    />
                    <span
                      >Infraestrutura para ar condicionado nas áreas comuns e
                      lojas comerciais</span
                    >
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-usb.svg"
                      alt=""
                    />
                    <span>Tomada USB nas áreas comuns</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-wifi.svg"
                      alt=""
                    />
                    <span>Infraestrutura para Wifi nas áreas comuns</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-front.svg"
                      alt=""
                    />
                    <span>Fachada revestida parcialmente em porcelanato</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-cabinet.svg"
                      alt=""
                    />
                    <span
                      >Armários inteligentes (locker) para recebimento de
                      entregas em domicílio</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <img
              class="image-datasheet"
              src="~@/assets/images/bg-goeasy-slide.png"
              alt=""
            />
          </swiper-slide>
          <swiper-slide class="slide">
            <div class="differentials">
              <div class="differentials_box">
                <img
                  class="differentials-logo"
                  src="~@/assets/images/logo-goeco.svg"
                  alt=""
                />
                <div class="differentials_infos">
                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-basins.svg"
                      alt=""
                    />
                    <span>
                      Bacias Dual Flush nas áreas comuns e nos apartamentos
                    </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-faucets.svg"
                      alt=""
                    />
                    <span>
                      Torneiras com arejador nas áreas comuns e nos apartamentos
                    </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-lamps.svg"
                      alt=""
                    />
                    <span>
                      Luminárias com sensor de presença no hall das torres
                    </span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-lighting.svg"
                      alt=""
                    />
                    <span>Iluminação em LED nas áreas comuns</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-gas.svg"
                      alt=""
                    />
                    <span>Previsão de medição individualizada de gás</span>
                  </p>

                  <p>
                    <img
                      class="icon-differentials"
                      src="~@/assets/images/icon-bicycle.svg"
                      alt=""
                    />
                    <span>Bicicletário</span>
                  </p>
                </div>
              </div>
            </div>
            <img
              class="image-datasheet"
              src="~@/assets/images/bg-goeco-slide.png"
              alt=""
            />
          </swiper-slide>
        </swiper>
        <div class="box-controllers">
          <div class="pagination-static">
            <div v-for="(menu, index) in mockPagination" :key="index">
              <div
                @click="prevSwiper(0)"
                class="swiper-button-prev"
                slot="button-prev"
              ></div>
              <button
                class="pagination-button"
                v-if="index === 0"
                :class="{ active: index === setIndex }"
                @click="prevSwiper(index)"
              >
                {{ menu }}
              </button>
              <button
                class="pagination-button"
                :class="{ active: index === setIndex }"
                v-if="index === 1"
                @click="nextSwiper(index)"
              >
                {{ menu }}
              </button>
            </div>
            <div
              @click="nextSwiper(1)"
              class="swiper-button-next"
              slot="button-next"
            ></div>
          </div>
        </div>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ProjectDifferentials",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      mockPagination: ["Go Easy", "Go Eco"],
      setIndex: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 50,
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  methods: {
    prevSwiper(i) {
      this.setIndex = i;
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    nextSwiper(i) {
      this.setIndex = i;
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
/* Swiper Styles*/
::v-deep.swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 0px;

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }

  @media (max-width: 568px) {
  }
}

.box-controllers {
  position: absolute;
  height: 146px;
  width: 620px;
  bottom: 200px;
  right: 0;
  z-index: 99;
  background: var(--tumbleweed);
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-static {
    display: flex;
    margin-top: 15px;

    &::before {
      content: "";
      width: 1px;
      height: 116px;
      position: absolute;
      top: 15px;
      left: 121px;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "";
      width: 1px;
      height: 116px;
      position: absolute;
      top: 15px;
      right: 121px;
      background: rgba(0, 0, 0, 0.1);
    }
    .pagination-button {
      color: var(--oasis);
      font-size: 20px;
      line-height: 20px;
      border: none;
      border-radius: none;
      text-decoration: none;
      cursor: pointer;
      background: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 26px;

      &.active {
        color: var(--blackRose);
        font-weight: var(--weight-bold);

        &:after {
          content: "";
          border-radius: 100%;
          width: 5px;
          height: 5px;
          background: var(--blackRose);
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  .swiper-button-next {
    right: 0;
    left: auto;
    height: 146px;
    top: 30px;
    width: 120px;
  }

  .swiper-button-prev {
    left: 0;
    right: auto;
    height: 146px;
    top: 30px;
    width: 120px;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 100px;
    width: 400px;
    bottom: 134px;

    .pagination-static {
      &::before {
        height: 70px;
        top: 15px;
        left: 81px;
      }

      &::after {
        height: 70px;
        top: 15px;
        right: 81px;
      }

      .pagination-button {
        font-size: 14px;
        line-height: 14px;
      }
    }

    .swiper-button-next {
      height: 100px;
      top: 22px;
      width: 80px;
    }

    .swiper-button-prev {
      height: 100px;
      top: 22px;
      width: 80px;
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    height: 70px;
    width: 163px;
    bottom: 135px;
    right: 100px;

    .swiper-button-prev,
    .swiper-button-next {
      height: 80px;
    }
    .pagination-static {
      .pagination-button {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    bottom: 134px;
    right: 180px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    bottom: -10px;
    right: 0px;
  }
}
/* Swiper Styles*/
.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: 10px;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;

  .slide {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .image-datasheet {
    object-fit: cover;
    max-width: 800px;
    height: 880px;
    border-radius: 30px 0 0 0;
  }

  .differentials {
    margin-top: 50px;
    .differentials_box {
      padding: 57px;
      background-color: var(--tumbleweed);
      height: 767px;
      width: 538px;
      border-radius: 30px;
      box-sizing: border-box;

      .differentials-logo {
        max-width: 150px;
        margin-bottom: 30px;
      }
      .differentials_infos {
        overflow-y: auto;
        height: 600px;

        p {
          font-size: 20px;
          line-height: 26px;
          color: var(--blackRose);
          margin-bottom: 40px;
          display: flex;
          align-items: center;

          &:last-child {
            margin-bottom: 0;
          }

          .icon-differentials {
            margin-right: 20px;
          }

          span {
            padding-right: 10px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: var(--oasis);
        }
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-datasheet {
      max-width: 490px;
      height: 700px;
    }

    .differentials {
      .differentials_box {
        padding: 38px;
        height: 500px;
        width: 380px;

        .differentials_infos {
          height: 340px;

          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 530px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .image-datasheet {
      max-width: 290px;
      height: 350px;
    }

    .differentials {
      margin-top: 20px;

      .differentials_box {
        padding: 18px;
        height: 270px;
        width: 270px;
        margin-right: 20px;

        .differentials-logo {
          max-width: 100px;
          margin-bottom: 20px;
        }

        .differentials_infos {
          height: 170px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 478px;
    max-height: 210px;

    .image-datasheet {
      max-width: 250px;
      height: 290px;
    }

    .differentials {
      .differentials_box {
        height: 180px;
        width: 210px;

        .differentials-logo {
          margin-bottom: 15px;
        }

        .differentials_infos {
          height: 100px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 568px) {
    max-width: 380px;

    .image-datasheet {
      max-width: 200px;
      height: 280px;
    }

    .differentials {
      .differentials_box {
        height: 180px;

        .differentials-logo {
          margin-bottom: 5px;
        }

        .differentials_infos {
          height: 110px;

          p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 12px;

            .icon-differentials {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--tumbleweed);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
