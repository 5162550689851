<template>
  <layout-stage backgroundColor="#9eb9df" themeName="units">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Units' }" exact>
              <icon-apartment />
              Apartamentos
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Units360' }" exact>
              <icon-compass />
              Vista 360º
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper class="swiper" ref="unit360Swiper" :options="swiperOptions">
          <swiper-slide class="slide slide-10">
            <button @click="toggleMode10()" class="button-mode">
              <span v-if="!modeNight10">
                <img src="~@/assets/images/icon-moonstars.svg" alt="" />
                Ver modo noturno</span
              >
              <span v-if="modeNight10">
                <img src="~@/assets/images/icon-sun.svg" alt="" />
                Ver modo diurno</span
              >
            </button>
            <iframe
              v-if="!modeNight10"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/920591c1a5454394b7dde66924117489?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
            <iframe
              v-if="modeNight10"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/556d4419963d4277a7a07101c3769a2a?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
          </swiper-slide>
          <swiper-slide class="slide slide-15">
            <button @click="toggleMode15()" class="button-mode">
              <span v-if="!modeNight15">
                <img src="~@/assets/images/icon-moonstars.svg" alt="" />
                Ver modo noturno</span
              >
              <span v-if="modeNight15">
                <img src="~@/assets/images/icon-sun.svg" alt="" />
                Ver modo diurno</span
              >
            </button>
            <iframe
              v-if="!modeNight15"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/d5ab3f30897f4704a2ebcb41cff033a7?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
            <iframe
              v-if="modeNight15"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/3e1953f4ac574e889420d7dfcb627fe9?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
          </swiper-slide>
          <swiper-slide class="slide slide-24">
            <button @click="toggleMode24()" class="button-mode">
              <span v-if="!modeNight24">
                <img src="~@/assets/images/icon-moonstars.svg" alt="" />
                Ver modo noturno</span
              >
              <span v-if="modeNight24">
                <img src="~@/assets/images/icon-sun.svg" alt="" />
                Ver modo diurno</span
              >
            </button>
            <iframe
              v-if="!modeNight24"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/7d400ac092d04b7b9232a70c2d41d290?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
            <iframe
              v-if="modeNight24"
              width="100%"
              height="500px"
              class="iframe-360"
              allowfullscreen="false"
              src="https://momento360.com/e/u/f0d73e3dd4fb4c96861db249c94f28e7?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium"
            >
            </iframe>
          </swiper-slide>
        </swiper>
        <div class="box-controllers">
          <div class="pagination-static">
            <div v-for="(menu, index) in mockPagination" :key="index">
              <button
                class="pagination-button"
                :class="{ active: index === setIndex }"
                @click="goSlider(index)"
              >
                {{ menu }}
              </button>
            </div>
          </div>
        </div>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconApartment from "@/components/icons/IconApartment";
import IconCompass from "@/components/icons/IconCompass";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Units360",

  components: {
    LayoutStage,
    IconBack,
    IconApartment,
    IconCompass,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      mockPagination: ["10º andar", "15º andar", "25º andar"],
      setIndex: 0,
      modeNight10: false,
      modeNight15: false,
      modeNight24: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 50,
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      },
    };
  },

  methods: {
    goSlider(i) {
      this.setIndex = i;
      this.$refs.unit360Swiper.$swiper.slideTo(i, 500, false);
    },
    toggleMode10() {
      this.modeNight10 = !this.modeNight10;
    },
    toggleMode15() {
      this.modeNight15 = !this.modeNight15;
    },
    toggleMode24() {
      this.modeNight24 = !this.modeNight24;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Swiper Styles*/

.button-mode {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 11;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blackRose);
  font-size: 20px;
  line-height: 20px;
  border: none;
  background: var(--oasis);
  border-radius: 50px;
  width: 250px;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin-right: 12px;
  }

  //Responsive mode

  @media (max-width: 1680px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 150px;
    height: 40px;
    font-size: 12px;
    line-height: 12px;

    img {
      width: 20px;
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }

  @media (max-width: 568px) {
  }
}

.box-controllers {
  position: absolute;
  height: 150px;
  width: 200px;
  bottom: 230px;
  right: 30px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .pagination-static {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    div:nth-child(1) {
      order: 2;
    }

    div:nth-child(2) {
      order: 1;
    }

    div:nth-child(3) {
      order: 0;
    }

    .pagination-button {
      color: var(--blackRose);
      font-size: 14px;
      line-height: 14px;
      border: none;
      border-radius: 50px;
      text-decoration: none;
      cursor: pointer;
      background: var(--oasis);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 12px 0;
      height: 40px;
      width: 101px;

      &:hover,
      &:active,
      &.active {
        color: var(--oasis);
        background: var(--blackRose);
        font-weight: var(--weight-bold);
      }
    }
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    bottom: 170px;
  }

  @media (max-width: 1366px) {
    bottom: 180px;
  }

  @media (max-width: 1200px) {
    bottom: 220px;
  }

  @media (max-width: 1024px) {
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    bottom: 10px;

    .pagination-static {
      .pagination-button {
        font-size: 12px;
        line-height: 12px;
        margin: 6px 0;
        height: 35px;
        width: 91px;
      }
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
  }

  @media (max-width: 568px) {
  }
}
/* Swiper Styles*/

.iframe-360 {
  border-radius: 30px 0 0 0;
  width: 1340px;
  z-index: 10;
  top: 0px;
  height: 925px;
  display: block;
  margin: 10px 0 0 0;
  border: 0 none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 709px;
    width: 940px;
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    width: 850px;
    height: 685px;
    top: 55px;
    right: -75px;
  }

  @media (max-width: 1024px) {
    width: 760px;
    height: 628px;
    top: 45px;
    right: -75px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    width: 580px;
    height: 330px;
    top: 15px;
    right: -75px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    width: 520px;
    height: 230px;
    top: 15px;
    right: -75px;
  }

  @media (max-width: 568px) {
    width: 440px;
    height: 235px;
    top: 15px;
    right: -75px;
  }
}

.block-content {
  margin-top: 50px;
  max-width: 1300px;
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;

  //Responsive mode
  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    margin-top: 0px;
    max-width: 900px;
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    margin-top: 50px;
    max-width: 810px;
  }

  @media (max-width: 1024px) {
    max-width: 715px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    margin-top: 10px;
    max-width: 528px;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 476px;
    max-height: 210px;
  }

  @media (max-width: 568px) {
    max-width: 378px;
  }
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.back-button {
  color: var(--white);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--white);
  }
}

.block-menu_secondary {
  a {
    color: var(--white);

    ::v-deep.icon-menu {
      stroke: var(--white);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--apricot);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
