<template>
  <layout-stage backgroundColor="#fcebc3" themeName="project">
    <template slot="nav-layout">
      <nav class="block-menu animate__animated animate__bounceInLeft">
        <router-link class="back-button" :to="{ name: 'Home' }" exact>
          <icon-back class="icon-menu" />
          Voltar
        </router-link>

        <ul class="block-menu_secondary">
          <li>
            <router-link :to="{ name: 'Project' }" exact>
              <icon-project />
              Projetistas
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'ProjectSectorization' }" exact>
              <icon-sectorization />
              Setorização
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectImplantation' }" exact>
              <icon-implantation />
              Implantação
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDatasheet' }" exact>
              <icon-datasheet />
              Ficha técnica
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ProjectDifferentials' }" exact>
              <icon-differentials />
              Diferenciais
            </router-link>
          </li>
        </ul>
      </nav>
    </template>
    <template slot="main-layout">
      <section class="block-content animate__animated animate__bounceInRight">
        <swiper
          class="swiper"
          ref="sectorizationSwiper"
          :options="swiperOptions"
        >
          <swiper-slide
            class="slide"
            v-for="(sector, index) in mockSectorization"
            :key="index"
          >
            <img class="image-sectorization" :src="sector.image" alt="" />
          </swiper-slide>

          <div class="box-controllers">
            <div class="pagination-static">
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div
                class="swiper-pagination swiper-pagination-bullets"
                slot="pagination"
              ></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </div>
          </div>
        </swiper>
      </section>
    </template>
  </layout-stage>
</template>

<script>
import LayoutStage from "@/components/LayoutStage";
import IconBack from "@/components/icons/IconBack";
import IconProject from "@/components/icons/IconProject";
import IconSectorization from "@/components/icons/IconSectorization";
import IconImplantation from "@/components/icons/IconImplantation";
import IconDatasheet from "@/components/icons/IconDatasheet";
import IconDifferentials from "@/components/icons/IconDifferentials";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ProjectSectorization",

  components: {
    LayoutStage,
    IconBack,
    IconProject,
    IconSectorization,
    IconImplantation,
    IconDatasheet,
    IconDifferentials,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      mockSectorization: [
        {
          image: require("@/assets/images/img-apart-inicial.png"),
          name: "Inicial",
        },
        {
          image: require("@/assets/images/img-apart-ponta.png"),
          name: "Ponta",
        },
        {
          image: require("@/assets/images/img-apart-meio.png"),
          name: "Meio",
        },
        {
          image: require("@/assets/images/img-apart-cobertura.png"),
          name: "Cobertura",
        },
      ],
      swiperOptions: {
        effect: "fade",
        speed: 1000,
        loop: false,
        slidesPerView: 1,
        simulateTouch: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet(index, className) {
            const types = {
              0: "Inicial",
              1: "Ponta",
              2: "Meio",
              3: "Cobertura",
            };
            return `<span class="${className} swiper-pagination-bullet-custom ${
              "swiper-pagination_" + index
            }">${types[index] ?? "Type not found"}</span>`;
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.box-controllers {
  position: absolute;
  height: 146px;
  width: 620px;
  bottom: 3px;
  right: 0px;
  z-index: 999;
  background: var(--tumbleweed);
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-static {
    display: flex;
    margin-top: 15px;

    &::before {
      content: "";
      width: 1px;
      height: 116px;
      position: absolute;
      top: 15px;
      left: 121px;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "";
      width: 1px;
      height: 116px;
      position: absolute;
      top: 15px;
      right: 121px;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  ::v-deep.swiper-pagination {
    display: flex;
    top: 70px;
    left: 140px;

    .swiper-pagination-bullet {
      width: inherit;
      height: inherit;
      font-size: 16px;
      line-height: 16px;
      font-weight: var(--weight-semibold);
      background: none;
      color: var(--white);
      opacity: 1;
      margin: 0 30px;
    }

    .swiper-pagination-bullet-active {
      color: var(--blackRose);
      font-weight: var(--weight-bold);
    }

    .swiper-pagination_0 {
      display: none;
    }
  }

  .swiper-button-next {
    right: 0;
    left: auto;
    height: 146px;
    top: 30px;
    width: 120px;
  }

  .swiper-button-prev {
    left: 0;
    right: auto;
    height: 146px;
    top: 30px;
    width: 120px;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-next:after {
    color: var(--blackRose);
    font-size: 22px;
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    height: 100px;
    width: 410px;
    bottom: 16px;

    .pagination-static {
      &::before {
        height: 70px;
        top: 15px;
        left: 81px;
      }

      &::after {
        height: 70px;
        top: 15px;
        right: 81px;
      }
    }

    ::v-deep.swiper-pagination {
      top: 44px;
      left: 90px;

      .swiper-pagination-bullet {
        font-size: 14px;
        line-height: 14px;
        margin: 0 15px;
      }
    }

    .swiper-button-next {
      height: 100px;
      top: 24px;
      width: 80px;
    }

    .swiper-button-prev {
      height: 100px;
      top: 24px;
      width: 80px;
    }
  }

  @media (max-width: 1366px) {
    bottom: 49px;
  }

  @media (max-width: 1200px) {
    height: 70px;
    width: 163px;
    bottom: 13px;
    right: 0px;

    &::before,
    &::after {
      height: 20px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 75px;
    }
    .swiper-pagination {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    bottom: 78px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    bottom: 78px;
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    bottom: 117px;
  }

  @media (max-width: 568px) {
    bottom: 172px;
  }
}

.block-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.block-content {
  margin-top: -11px;
  max-width: 1235px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;

  .slide {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .image-sectorization {
    object-fit: cover;
    max-width: 1369px;
    height: 964px;
    border-radius: 30px 0 0 0;
  }

  //Responsive mode

  @media (max-width: 1680px) {
  }

  @media (max-width: 1440px) {
    max-width: 900px;

    .image-sectorization {
      max-width: 900px;
      height: 703px;
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) {
    max-width: 800px;
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    max-width: 714px;
  }

  @media only screen and (max-width: 926px) and (orientation: landscape) {
    max-width: 527px;
    position: fixed;
    right: 0;
    bottom: 10px;

    .image-sectorization {
      max-width: 527px;
      height: 412px;
    }
  }

  @media only screen and (max-width: 780px) and (orientation: landscape) {
    max-width: 477px;
    max-height: 245px;
    .image-sectorization {
      max-width: 527px;
      height: 373px;
    }
  }

  @media (max-width: 568px) {
    max-width: 378px;
  }
}

.back-button {
  color: var(--blackRose);
  font-weight: var(--weight-regular);

  ::v-deep.icon-menu {
    stroke: var(--blackRose);
  }
}

.block-menu_secondary {
  a {
    color: var(--tumbleweed);

    ::v-deep.icon-menu {
      stroke: var(--tumbleweed);
    }

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: var(--blackRose);
      background: var(--tumbleweed);

      ::v-deep.icon-menu {
        stroke: var(--blackRose);
      }
    }
  }
}
</style>
